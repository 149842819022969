<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>အရွေး လက်ခံ
							<small class="badge badge-primary"
								v-if="dataList.status == 'processing'">အပေါင်မရွေးရေသး</small>
							<small class="badge badge-success" v-if="dataList.status == 'completed'">အပေါင်
								ရွေးပြီး</small>
							<small class="badge badge-danger" v-if="dataList.status == 'loss'">အပေါင်းဆုံး</small>
						</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputInterest" class="col-sm-2 col-form-label ">အတိုးပြင်ရန် </label>
								<div class="col-sm-10">
									<input type="number" v-model="mortgageRequest.interest" @blur="validationRule()"
										class="form-control" id="inputInterest">
								</div>
							</div>

							<div v-if="this.$role == 'admin'" class="form-group row">
								<label for="inputShopId" class="col-sm-2 col-form-label">ဆိုင်ခွဲ အမည်</label>
								<div class="col-sm-10">
									<select id="inputShopId" class="form-control" v-model="mortgageRequest.shopId"
										@change="validationRule()">
										<option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
									</select>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputItemId" class="col-sm-2 col-form-label text-danger">ပစ္စည်း
									အမျိုးအစား</label>
								<div class="col-sm-10">
									<select id="inputItemId" class="form-control" v-model="mortgageRequest.itemId"
										@change="validationRule()">
										<option value="">ရွေးချယ်မည်</option>
										<option v-for="item in itemList" :value="item.id">{{item.name}}</option>
									</select>
									<small class="text-danger">{{validationData.itemId}}</small>
								</div>
							</div>

							<!--  -->
							<div class="form-group row">
								<label for="inputMortgageNo" class="col-sm-2 col-form-label text-danger">ဘောင်ချာ
									အမှတ်</label>
								<div class="col-sm-3">
									<select class="form-control" v-model="mortgageRequest.noType"
										@change="validationRule()">
										<option value="No">ဆိုင်ခွဲမရှိ</option>
									</select>
								</div>
								<div class="col-sm-7">
									<input type="text" v-model="mortgageRequest.no" @blur="validationRule()"
										class="form-control" id="inputMortgageNo">
									<small class="text-danger">{{validationData.no}}</small>
								</div>
							</div>
							<!--  -->

							<div class="form-group row">
								<label for="inputMortgageType" class="col-sm-2 col-form-label text-danger">ပစ္စည်း
									နာမည်</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.type" @blur="validationRule()"
										class="form-control" id="inputMortgageType">
									<small class="text-danger">{{validationData.type}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputMortgageWeight" class="col-sm-2 col-form-label ">ကျောက်ပါ
									အလေးချိန်</label>
								<div class="col-sm-3">
									<input type="text" @keyup="changeV1()" v-model="mortgageRequest.mortgageWeight.v1"
										@blur="validationRule()" class="form-control" placeholder="ကျပ်">
								</div>
								<div class="col-sm-3">
									<input type="text" @keyup="changeV2()" v-model="mortgageRequest.mortgageWeight.v2"
										@blur="validationRule()" class="form-control" placeholder="ပဲ">
								</div>
								<div class="col-sm-4">
									<input type="text" @keyup="changeV3()" v-model="mortgageRequest.mortgageWeight.v3"
										@blur="validationRule()" class="form-control" placeholder="ရွေး">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoan" class="col-sm-2 col-form-label text-danger">ချေးငွေ (ks) </label>
								<div class="col-sm-10">
									<input type="number" v-model="mortgageRequest.loan" @keyup="changeFont()"
										@blur="validationRule()" class="form-control" id="inputLoan">
									<small class="text-danger">{{validationData.loan}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoanMMK" class="col-sm-2 col-form-label text-danger">ချေးငွေ (ကျပ်)
								</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.loanMmk" @blur="validationRule()"
										class="form-control" id="inputLoanMMK">
									<small class="text-danger">{{validationData.loanMmk}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoanText" class="col-sm-2 col-form-label text-danger">ချေးငွေ (စာဖြင့်)
								</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.loanText" @blur="validationRule()"
										class="form-control" id="inputLoanText">
									<small class="text-danger">{{validationData.loanText}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">ပေါင်နှံသူ အမည်
								</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputNrc" class="col-sm-2 col-form-label">မှတ်ပုံတင် အမှတ် </label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.nrc" @blur="validationRule()"
										class="form-control" id="inputNrc">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label text-danger">နေရပ်လိပ်စာ
								</label>
								<div class="col-sm-10">
									<textarea v-model="mortgageRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress"></textarea>
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputStartDate"
									class="col-sm-2 col-form-label text-danger">ပေါင်နှံသည့်နေ့စွဲ </label>
								<div class="col-sm-10">
									<input type="date" @change="changeDate()" v-model="mortgageRequest.startDate"
										@blur="validationRule()" class="form-control" id="inputStartDate">
									<small class="text-danger">{{validationData.startDate}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEndDate"
									class="col-sm-2 col-form-label text-danger">အပေါင်သက်တမ်းကုန်ဆုံးမည့်နေ့ </label>
								<div class="col-sm-10">
									<input type="date" v-model="mortgageRequest.endDate" @blur="validationRule()"
										class="form-control" id="inputEndDate">
									<small class="text-danger">{{validationData.endDate}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputInterestType"
									class="col-sm-2 col-form-label text-danger">အတိုးနှုန်း</label>
								<div class="col-sm-5">
									<select class="form-control" v-model="mortgageRequest.interestType">
										<option value="percent">ရာခိုင်နှုန်း</option>
										<option value="fix">ပုံသေ</option>
									</select>
								</div>
								<div class="col-sm-5">
									<input type="number" v-model="mortgageRequest.interestRate" @blur="validationRule()"
										class="form-control" step="0.1">
									<small class="text-danger">{{validationData.interestRate}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputWeight" class="col-sm-2 col-form-label ">အလေးချိန်</label>
								<div class="col-sm-3">
									<input type="text" v-model="mortgageRequest.weight.v1" @blur="validationRule()"
										class="form-control" placeholder="ကျပ်">
								</div>
								<div class="col-sm-3">
									<input type="text" v-model="mortgageRequest.weight.v2" @blur="validationRule()"
										class="form-control" placeholder="ပဲ">
								</div>
								<div class="col-sm-4">
									<input type="text" v-model="mortgageRequest.weight.v3" @blur="validationRule()"
										class="form-control" placeholder="ရွေး">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputJewelWeight" class="col-sm-2 col-form-label ">ကျောက်ချိန်</label>
								<div class="col-sm-3">
									<input type="text" v-model="mortgageRequest.jewelWeight.v1" @blur="validationRule()"
										class="form-control" placeholder="ကျပ်">
								</div>
								<div class="col-sm-3">
									<input type="text" v-model="mortgageRequest.jewelWeight.v2" @blur="validationRule()"
										class="form-control" placeholder="ပဲ">
								</div>
								<div class="col-sm-4">
									<input type="text" v-model="mortgageRequest.jewelWeight.v3" @blur="validationRule()"
										class="form-control" placeholder="ရွေး">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputGoldWeight" class="col-sm-2 col-form-label ">ရွှေချိန်</label>
								<div class="col-sm-3">
									<input type="text" v-model="mortgageRequest.goldWeight.v1" @blur="validationRule()"
										class="form-control" placeholder="ကျပ်">
								</div>
								<div class="col-sm-3">
									<input type="text" v-model="mortgageRequest.goldWeight.v2" @blur="validationRule()"
										class="form-control" placeholder="ပဲ">
								</div>
								<div class="col-sm-4">
									<input type="text" v-model="mortgageRequest.goldWeight.v3" @blur="validationRule()"
										class="form-control" placeholder="ရွေး">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputMortgageStatus" class="col-sm-2 col-form-label">အခြေအနေ</label>
								<div class="col-sm-10">
									<select class="form-control" id="inputMortgageStatus" v-model="mortgageRequest.status" @change="validationRule()">
										<option value="processing">မရွေးရေသး</option>
										<option value="completed">ရွေးပြီး</option>
									</select>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoss" class="col-sm-2 col-form-label">လက်မှတ်ပျောက် </label>
								<div class="col-sm-10">
									<input type="checkbox" v-model="mortgageRequest.loss" id="inputLoss">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputKeep" class="col-sm-2 col-form-label">အကြီးသိမ်း </label>
								<div class="col-sm-10">
									<input type="checkbox" v-model="mortgageRequest.keep" id="inputKeep">
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	import moment from 'moment';
	export default {
		name: 'mortgageCreate',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				shopList: [],
				itemList: [],
				dataList: [],
				mortgageRequest: {
					id: "",
					shopId: "",
					itemId: "",
					noType: "No",
					no: "",
					type: "",
					mortgageWeight: {
						v1: "",
						v2: "",
						v3: ""
					},
					loan: "",
					loanMmk: "",
					loanText: "",
					name: "",
					nrc: "",
					address: "",
					startDate: "",
					endDate: "",
					interest: "",
					interestType: "percent",
					interestRate: 3,
					status: "",
					keep: '',
					loss: '',
					goldWeight: {
						v1: "",
						v2: "",
						v3: ""
					},
					jewelWeight: {
						v1: "",
						v2: "",
						v3: ""
					},
					weight: {
						v1: "",
						v2: "",
						v3: ""
					}
				},
				validationData: {
					itemId: "",
					no: "",
					type: "",
					loan: "",
					loanMmk: "",
					loanText: "",
					name: "",
					nrc: "",
					address: "",
					startDate: "",
					endDate: "",
					interestRate: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.dataList.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				mortgageFieldsAction: 'mortgageFieldsAction',
				mortgageStoreAction: 'mortgageStoreAction',
				mortgageDetailAction: 'mortgageDetailAction',
			}),
			changeFont() {
				let myNumber = this.toMyanmar(this.mortgageRequest.loan, "my")
				this.mortgageRequest.loanMmk = myNumber
				let myWord = this.myanmarNumToWord(this.mortgageRequest.loan)
				this.mortgageRequest.loanText = myWord
			},
			changeV1() {
				this.mortgageRequest.weight.v1 = this.mortgageRequest.mortgageWeight.v1
			},
			changeV2() {
				this.mortgageRequest.weight.v2 = this.mortgageRequest.mortgageWeight.v2
			},
			changeV3() {
				this.mortgageRequest.weight.v3 = this.mortgageRequest.mortgageWeight.v3
			},
			changeDate() {
				this.mortgageRequest.endDate = moment(this.mortgageRequest.startDate).add(4, 'M').format('YYYY-MM-DD')
			},
			async getFields() {
				let option = {
					fields: {
						type: "field"
					}
				}
				await this.mortgageFieldsAction({
					...option
				}).then(res => {
					this.shopList = res.data.data.shop
					this.itemList = res.data.data.item
				}).catch(err => this.isLoading = true)
			},
			async getDetail() {
				let option = {
					id: this.$route.query.id
				}
				await this.mortgageDetailAction({
					...option
				}).then(res => {
					this.dataList = res.data.data
					this.mortgageRequest.id = res.data.data.id
					this.mortgageRequest.shopId = res.data.data.shop_id
					this.mortgageRequest.itemId = res.data.data.item_id
					this.mortgageRequest.noType = res.data.data.no_type
					this.mortgageRequest.no = res.data.data.no
					this.mortgageRequest.type = res.data.data.type
					this.mortgageRequest.mortgageWeight.v1 = res.data.data.mortgage_weight.v1
					this.mortgageRequest.mortgageWeight.v2 = res.data.data.mortgage_weight.v2
					this.mortgageRequest.mortgageWeight.v3 = res.data.data.mortgage_weight.v3
					this.mortgageRequest.loan = res.data.data.loan
					this.mortgageRequest.loanMmk = res.data.data.loan_mmk
					this.mortgageRequest.loanText = res.data.data.loan_text
					this.mortgageRequest.name = res.data.data.name
					this.mortgageRequest.nrc = res.data.data.nrc
					this.mortgageRequest.address = res.data.data.address
					this.mortgageRequest.interest = res.data.data.interest
					this.mortgageRequest.interestType = res.data.data.interest_type
					this.mortgageRequest.interestRate = res.data.data.interest_rate
					this.mortgageRequest.status = res.data.data.status
					this.mortgageRequest.keep = res.data.data.keep
					this.mortgageRequest.loss = res.data.data.loss
					this.mortgageRequest.startDate = res.data.data.start_date
					this.mortgageRequest.endDate = res.data.data.end_date
					this.mortgageRequest.goldWeight.v1 = res.data.data.gold.v1
					this.mortgageRequest.goldWeight.v2 = res.data.data.gold.v2
					this.mortgageRequest.goldWeight.v3 = res.data.data.gold.v3
					this.mortgageRequest.jewelWeight.v1 = res.data.data.jewel.v1
					this.mortgageRequest.jewelWeight.v2 = res.data.data.jewel.v2
					this.mortgageRequest.jewelWeight.v3 = res.data.data.jewel.v3
					this.mortgageRequest.weight.v1 = res.data.data.weight.v1
					this.mortgageRequest.weight.v2 = res.data.data.weight.v2
					this.mortgageRequest.weight.v3 = res.data.data.weight.v3
					let month = moment(res.data.data.start_date).format('M')
					if (this.mortgageRequest.filterType == 'type2') {
						this.mortgageRequest.referenceNote = month + "×" + res.data.data.no + "(လဲ)"
					}
					if (this.mortgageRequest.filterType == 'type3') {
						this.mortgageRequest.referenceNote = month + "×" + res.data.data.no + "(သွင်း)"
					}
					if (this.mortgageRequest.filterType == 'type4') {
						this.mortgageRequest.referenceNote = month + "×" + res.data.data.no + "(ယူ)"
					}
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.mortgageRequest
				await this.mortgageStoreAction({
					...option
				}).then(res => {
					this.$router.replace({
						path: 'mortgage-all-list',
						query: {
							page: 1,
							reverse: 'desc',
							sort: 'created_at',
							liststatus: 'all',
							notype: '',
							nofrom: '',
							noto: '',
							no: '',
							name: '',
							address: '',
							loan: '',
							shop: '',
							item: '',
							startdate: '',
							enddate: '',
							status: '',
							referencestatus: '',
							nodate: '',
							paydate: '',
							paydate_to: '',
							expdate_from: '',
							expdate_to: '',
							otherstatus: '',
							inventoryMode: ''
						}
					}).catch(() => {})
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.mortgageRequest.itemId == "") {
					this.validationData.itemId = "ပစ္စည်းအမျိုးအစား ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.itemId = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.no == "") {
					this.validationData.no = "ဘောင်ချာ အမှတ် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.no = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.type == "") {
					this.validationData.type = "ပစ္စည်းအမည် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.type = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.mortgageWeight == "") {
					this.validationData.mortgageWeight = "ကျောက်ပါ အလေးချိန် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.mortgageWeight = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.loan == "") {
					this.validationData.loan = "ချေးငွေ (ks) ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.loan = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.loanMmk == "") {
					this.validationData.loanMmk = "ချေးငွေ (ကျပ်) ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.loanMmk = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.loanText == "") {
					this.validationData.loanText = "ချေးငွေ (စာဖြင့်) ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.loanText = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.name == "") {
					this.validationData.name = "ပေါင်နှံသူ အမည် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.address == "") {
					this.validationData.address = "နေရပ်လိပ်စာ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.startDate == "") {
					this.validationData.startDate = "ပေါင်နှံသည့်နေ့စွဲ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.startDate = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.endDate == "") {
					this.validationData.endDate = "အပေါင်သက်တမ်းကုန်ဆုံးမည့်နေ့ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.endDate = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.interestRate == "") {
					this.validationData.interestRate = "အတိုးနှုန်း ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.interestRate = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.mortgageRequest.startDate = moment(new Date()).format('YYYY-MM-DD')
			this.mortgageRequest.endDate = moment().add(4, 'M').format('YYYY-MM-DD')
			this.getDetail()
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}

	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>